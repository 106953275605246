@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap);
:root {
  --primary-color: orange;
  --danger-color: red;
  --success-color: green;
  --aboutUs-color: rgba(252, 224, 149, 0.527);
  --ourServices-color: rgb(202 243 238);
  --topBar-color: rgba(255, 255, 255, 0.6);
  --topBar-background-color: #112033;
}

/* General */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Scroll bar */

/* Works on Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: orange rgba(252, 224, 149, 0.527);
  scrollbar-color: var(--primary-color) var(--aboutUs-color);
}

/* Works on Chrome, Edge, and Safari */
html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-track {
  background: #ccc;
}

html::-webkit-scrollbar-thumb {
  background-color: orange;
  background-color: var(--primary-color);
  border-radius: 20px;
}

#root {
  height: 100%;
}
html {
  height: 100%;
  /* font-size: 62.5%; */
  font-family: "Source Sans Pro", sans-serif;
}
body {
  height: 100vh;
  min-height: 100vh;
  background-color: #f8f9fa !important;
}

ul li {
  list-style: none;
}

img {
  max-width: 100%;
}

input:valid,
textarea:valid {
  box-shadow: 0 0 0.1rem 0.1rem green !important;
  box-shadow: 0 0 0.1rem 0.1rem var(--success-color) !important;
}

input:focus,
textarea:focus {
  border: none !important;
  box-shadow: 0 0 0.1rem 0.2rem orange !important;
  box-shadow: 0 0 0.1rem 0.2rem var(--primary-color) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  background-color: orange !important;
  background-color: var(--primary-color) !important;
  box-shadow: none !important;
  border: none !important;
}
.btn:hover {
  box-shadow: none !important;
}
.btn:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  box-shadow: none !important;
}

/* Loader */
.loader {
  border: 0.5rem solid #ccc;
  border-radius: 50%;
  border-top: 0.5rem solid orange;
  border-top: 0.5rem solid var(--primary-color);
  width: 3rem;
  height: 3rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
/* whatsapp button */
.whatsapp-button{
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  cursor: pointer;
  background: #4fce5d;
  box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.4) !important;
}
/* Home Screen */
/* Header */

/* Top Bar */

#topbar {
  background: #112033;
  background: var(--topBar-background-color);
  /* height:4rem; */
  font-size: 14px;
  transition: color 0.5s;
  color: rgba(255, 255, 255, 0.6);
  color: var(--topBar-color);
}

#topbar .contact-info a {
  line-height: 1;
  color: rgba(255, 255, 255, 0.6);
  color: var(--topBar-color);
  text-decoration: none;
  transition: 0.3s;
}

#topbar .contact-info i {
  padding-right: 4px;
  margin-left: 15px;
}

#topbar .contact-info i:first-child {
  margin-left: 0;
}

#topbar .social-links a {
  color: rgba(255, 255, 255, 0.6);
  color: var(--topBar-color);
  padding-left: 15px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover,
#topbar .contact-info a:hover,
#topbar .close-topBar:hover{
  color: orange !important;
  color: var(--primary-color) !important;
}

.nav-link {
  color: #000 !important;
  text-decoration: none !important;
  font-weight: bold !important;
  margin: 5px;
  border: 1px solid #ccc !important;
  border-radius: 2rem;
  width: 10rem;
  transition: background-color 0.8s;
}
.nav-link.active {
  border: 1px solid #ccc !important;
  color: #ffffff !important;
  font-weight: 600;
  background-color: orange;
  background-color: var(--primary-color);
  transition: background-color 0.8s;
}

.navbar-toggler:not(.collapsed) {
  border: 3px solid orange !important;
  border: 3px solid var(--primary-color) !important;
  transition: border 0.2s;
}

/* social icons */
.social-icons a {
  padding: 0 0.5rem 0 0.5rem;
  font-size: 0.8rem;
}
/* Carousel */
.carousel-item img {
  -webkit-filter:grayscale(1) brightness(90%);
          filter:grayscale(1) brightness(90%);
}

.carousel-control-prev{left:2% !important;}
.carousel-control-next {right: 2% !important;}
.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
  width: auto !important;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* font-size: 3rem; */
  height: 3rem !important;
  width: 3rem !important;
  color: black !important;
  border: 0.3rem solid #ffffff !important;
  border-radius: 50%;
  opacity: 1 !important;
  background-image: none !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  text-align: center !important;
}

.carousel-control-prev-icon::after {
  padding: 5px 0 0 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='25' height='25' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}
.carousel-control-next-icon::after {
  padding: 5px 0 0 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='25' height='25' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.carousel-indicators li {
  height: 0.8rem !important;
  width: 0.8rem !important;
  border-radius: 50% !important;
}
/* About us */

.about-us {
  background-color: rgba(252, 224, 149, 0.527);
  background-color: var(--aboutUs-color);
}
/* our services */

.our-services {
  background-color: rgb(202 243 238);
  background-color: var(--ourServices-color);
}
.services-wrapper ul li .service-icon {
  height: 30px;
  width: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 50%;
  color: white;
  background-color: orange;
  background-color: var(--primary-color);
}

.services-wrapper ul li {
  margin-bottom: 2rem;
}
.services-wrapper ul li h6 {
  font-weight: bold;
}

/* Achievements */

.achivements {
  background-color: rgb(202 243 238);
  background-color: var(--ourServices-color);
  /* background-image: url(/images/carousel-img-1.jpg); */
  /* background-attachment: fixed; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}

/* Testimonials */

.testimonial-image {
  height: 8rem;
  width: 8rem !important;
  border-radius: 50%;
  box-shadow: 0 0 0.5rem 0.2rem rgba(0, 0, 0, 0.4);
}
.testimonials-rating {
  width: 9rem;
  height: 2rem;
  background: orange;
  background: var(--primary-color);
}


/* Footer */
footer{
  margin-top: auto;
  background-color: #112033;
  background-color: var(--topBar-background-color);
  color: #fff;
}

footer a{
  color:rgba(255, 255, 255, 0.6);
  color:var(--topBar-color);
}
footer a:hover,.address a:hover{
  color:orange;
  color:var(--primary-color);
  text-decoration: none !important;
}

/* Swiper slider */

.swiper-container {
  width: 100%;
  /* padding-top: 50px !important; */
  padding-bottom: 50px !important;
}

.swiper-slide {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  /* background-position: center; */
  /* background-size: cover; */
  width: 300px;
  /* height: 300px; */
  height: auto !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.mySwiper2 .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.7;
}

.mySwiper2 .swiper-slide-thumb-active {
  opacity: 1;
}

/* Responsive media queries */

@media only screen and (max-width: 576px) {
  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }

  .carousel-item .slide-1-caption,
  .carousel-item .slide-2-caption,
  .carousel-item .slide-3-caption,.carousel-item .slide-2 {
    left: 2%;
    right: 2%;
    bottom: 6%;
  }
}

@media only screen and (max-width: 768px) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 2rem !important;
    width: 2rem !important;
  }

  .carousel-control-prev-icon::after {
    padding: 3px 0 0 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='15' height='15' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
  }
  .carousel-control-next-icon::after {
    padding: 3px 0 0 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='15' height='15' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
  }

  .carousel-item img {
    width: 576px;
    height: 50vh;
    -webkit-filter:grayscale(1) brightness(90%);
            filter:grayscale(1) brightness(90%);
  }

  .carousel-caption {
    font-weight: 200 !important;
    font-size: 0.3rem !important;
    /* background:rgba(255, 255, 255, 0.3); */
  }
  .carousel-item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.2;
  }
}
@media only screen and (min-width: 768px) {
  .carousel-item img {
    width: 576px;
    height: 80vh;
  }
  .carousel-caption {
    font-size: 2rem;
  }
  .slide-button {
    /* font-size: 2rem; */
  }
  .carousel-item .slide-1-caption {
    top: 34%;
    left: 8%;
    bottom: 32%;
    right: 48%;
  }
  .carousel-item .slide-2-caption {
    top: 34%;
    left: 57%;
    bottom: 32%;
    right: 6%;
    /* color:black !important; */
  }
  .carousel-item .slide-3-caption {
    top: 31%;
    left: 9%;
    bottom: 33%;
    right: 56%;
  }
}

@media only Screen and (min-width:991px){
  .social-links{
    padding-right: 10rem !important;
  }
}

